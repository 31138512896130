/* Global Mixins and variables */
.clearfix:after {
  content: '';
  display: block;
  clear: both;
  height: 0;
}
@-moz-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flickerIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.PlatformTiles {
  max-width: 75rem;
  margin: 0 auto;
}
.PlatformTiles .Platform {
  display: inline-block;
  width: 50%;
  padding: 5rem;
  float: left;
  cursor: pointer;
  text-align: center;
  transition: 0.15s all;
  font-size: 2rem;
  font-weight: 600;
  color: #745a9e;
}
@media (max-width: 767px) {
  .PlatformTiles .Platform {
    width: 100%;
  }
}
.PlatformTiles .Platform:hover {
  transform: scale(1.1);
}
